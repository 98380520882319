호텔을 구해라 호텔 청소 플랫폼 호구153 젊은이들과 함께 제주도의 호텔을 구하러
간다 이전 페이지 주소 확인 리퍼럴

<template>
  <section class="sub-container">
    <sub-nav-with-user-info> </sub-nav-with-user-info>
    <main class="sub __find">
      <div class="inner">
        <div class="tit">
          <div class="find_tit">
            <!-- recruitingStatus -->
            <span class="status __rec">{{
              RECRUITING_STATUS[recruitingStatus]
            }}</span>
            <!--span class="status __emer">긴급</!--span-->
            <h1>{{ jobPostTitle }}</h1>
            <!-- 등록일자  -->
            <span class="date">등록일자 : {{ regDt }}</span>
            <span class="count" v-if="recruitingStatus === 'RECRUITING'">{{
              `D-${closingTime}`
            }}</span>
            <!--span class="count" v-else>모집마감</!--span-->
            <!--마감잔여시간 ex) D-21 -->
          </div>
        </div>
        <div class="sub-cont">
          <div class="company_info">
            <img :src="imgPath" alt="" class="logo" />
            <div class="info_box">
              <p class="t1">
                {{ companyName }}
                <span>{{ businessTypeName }}</span>
              </p>
              <p class="t2" id="copyAddress">
                {{ `${workingAddress} ${workingAddressDetail}` }}
              </p>
              <div v-if="isAddress" class="addressBox">
                <div @click="goLoadMap(companyName, latLon)">
                  <img
                    src="~assets/image/ico/FindingAWay_M.png"
                    alt="모바일 길찾기 아이콘"
                  />
                  <span>길찾기</span>
                </div>
                <div
                  @click="addressCopy(workingAddress + workingAddressDetail)"
                >
                  <img
                    src="~assets/image/ico/link.png"
                    alt="주소 복사 아이콘"
                  />
                  <span>주소복사</span>
                </div>
              </div>
            </div>
            <div v-if="!isAddress" class="addressBox">
              <div @click="goLoadMap(companyName, latLon)">
                <a href="javascript:" class="btn-findingAWay"></a>
                <p>길찾기</p>
              </div>
              <div @click="addressCopy(workingAddress + workingAddressDetail)">
                <a href="javascript:" class="btn-copyAddress"></a>
                <p>주소복사</p>
              </div>
            </div>
          </div>
          <div class="job_info_wrap">
            <div class="t-head">구인직종</div>
            <div class="t-doc">
              <p class="t1">
                {{
                  `${jobPositionName} ${jobPositionDtlName} ${positionCnt}명 ${positionOptionAdditionalNames}`
                }}
                <!-- 룸메이드 클리너 2명 (외국인가능, 시간제, 배팅2명) -->
              </p>
              <p class="t1">
                근무기간: {{ TERM_OPTION_KEY_VALUE[workPeriodType] }}
                <span>{{
                  `(${workStartDate} ~ ${workEndDate}, ${workStartTime}~${workEndTime})`
                }}</span>
                <!-- <span>(2021.07.21 ~ 2021.07.31, 10시~16시)</span> -->
              </p>
            </div>
          </div>
          <div class="job_info_wrap">
            <div class="t-head">급료&#47;1일</div>
            <div class="t-doc">
              <p class="t1">
                {{ `${insertCommaToNumber(payAmt)}원` }}
              </p>
            </div>
          </div>
          <div class="job_info_wrap">
            <div class="t-head">근무조건</div>
            <div class="t-doc">
              <p class="t1">
                성별: {{ gender }}
                <br />
                나이: {{ age }}
                {{ ` ${ageMin ? `${ageMin}세 ~ ${ageMax}세` : ''}` }}
                <br />
                경력: {{ career }} &nbsp;/
                <br class="m" />
                국가: {{ nationality }}
                <br />
                {{ `${foreignLanguage ? `외국어: ${foreignLanguage}` : ''}` }}
                <br />
                <br />
                {{ `${supportOptions ? `제공항목: ${supportOptions}` : ''}` }}
                <br />
                {{ `${majorInsurances ? `4대보험: ${majorInsurances}` : ''}` }}
                <br />
                {{ `${incentives ? `보수/수당: ${incentives}` : ''}` }}
                <br />
                {{ `${welfares ? `복지: ${welfares}` : ''}` }}
              </p>
            </div>
          </div>
          <div class="job_info_wrap" v-if="bannerImgPath !== ''">
            <div class="t-head">테마설명</div>
            <div class="t-doc">
              <img :src="themeImgPath" alt="" />
            </div>
          </div>
          <div class="job_info_wrap">
            <div class="t-head">우대사항</div>
            <div class="t-doc">
              <p class="t1">{{ spcConds }}</p>
            </div>
          </div>
          <div class="job_info_wrap">
            <div class="t-head">특이사항</div>
            <div class="t-doc">
              <p class="t1">{{ note }}</p>
            </div>
          </div>
          <div class="job_info_wrap">
            <div class="t-head">마감일시</div>
            <div class="t-doc">
              <p class="t1" style="color: #619ef6">{{ closingDt }}</p>
            </div>
          </div>
          <div class="job_info_wrap">
            <div class="t-head">지원상태</div>
            <div class="t-doc">
              <div class="_condition">
                <!-- <span>{{
                  `${RECRUIT_RESULT_OPTION_KEY_VALUE[passDecisionType]}${
                    passDecisionType === 'B'
                      ? ` ${passDecisionTime}시간 내`
                      : ''
                  }`
                }}</span> -->
                <span v-if="companyId == userId">
                  모집인원 : {{ positionCnt }} 명 &#47; 매칭인원 :
                  {{ scallCnt }} 명 &#47;응답인원 : {{ answerCnt }} 명 &#47;<br
                    id="companyStatusBr"
                  />
                  확정인원 : {{ sokCnt }} 명 &#47; 합격인원 : {{ passCnt }} 명
                </span>
                <span v-else>
                  {{ applyMessage }}
                  <!-- {{ companyId }} -->
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn_wrap">
        <button type="button" class="__applay" @click="goBackPage">
          이전 페이지
        </button>
        <!-- pushStatus와 정의 필요, 지원하기 API 필요 -->
        <!-- <template v-if="pushStatus !== ''">
          <button
            v-if="availableApply(pushStatus)"
            type="button"
            class="__applay"
            @click="applyJob"
          >
            {{ getPushStatusName(pushStatus) }}
          </button>
          <button v-else type="button" class="__call">
            {{ getPushStatusName(pushStatus) }}
          </button>
        </template> -->
        <!--button type="button" class="__call">CALL 상태</!--button-->
        <button
          v-if="pushStatus && isEmployee"
          type="button"
          :class="{
            __applay: isNegoAvailable,
            __call: !isNegoAvailable || recruitingStatus != 'RECRUITING',
          }"
          @click="callNego"
        >
          {{ pushStatusButtonText }}
        </button>
      </div>
      <normal-job-list
        v-if="!matchView"
        :list="generalList"
        :companyId="companyId"
        :userId="userId"
      ></normal-job-list>
      <match-people
        :certedDocumentNo="this.$route.params.ID"
        v-if="matchView"
      ></match-people>
    </main>
  </section>
</template>

<script>
import SubNavWithUserInfo from 'Common/SubNavWithUserInfo'
import { getRecruitDetail, postApplyJob } from 'Api/modules'
import jobDetailMixin from 'Mixins/jobDetail'
import findJobMixin from 'Mixins/findJob'
import matchPeople from 'Components/request/partials/matchPeople'
import { RECRUITING_STATUS } from 'Configs/registJob'
import { URL } from 'Api/api'
import {
  TERM_OPTION_KEY_VALUE,
  RECRUIT_RESULT_OPTION_KEY_VALUE,
} from 'Configs/registJob'
import { PUSH_STATUS, PUSH_STATUS_NAME } from 'Configs/pushStatus'
import NormalJobList from 'Components/find/content/NormalJobList'

export default {
  components: {
    SubNavWithUserInfo,
    NormalJobList,
    matchPeople,
  },
  mixins: [jobDetailMixin, findJobMixin],
  data() {
    return {
      RECRUITING_STATUS: Object.freeze(RECRUITING_STATUS),
      TERM_OPTION_KEY_VALUE: Object.freeze(TERM_OPTION_KEY_VALUE),
      RECRUIT_RESULT_OPTION_KEY_VALUE: Object.freeze(
        RECRUIT_RESULT_OPTION_KEY_VALUE,
      ),
      PUSH_STATUS_NAME: Object.freeze(PUSH_STATUS_NAME),
      isNegoUpdated: false,
      applyText: '',
      latlon: '',
      isAddress: false,
      matchView: false,
      window: {
        width: 0,
        height: 0,
      },
    }
  },
  watch: {
    $route(to, from) {
      if (to.path != from.path) {
        this.fetchJobDetail()
      }
    },
    window: {
      deep: true,
      handler() {
        if (this.window.width < 1023) {
          this.isAddress = true
        } else {
          this.isAddress = false
        }
      },
    },
  },
  computed: {
    imgPath() {
      // 회사 이미지 필요
      //console.info('this.catalogId', this.catalogId)
      if (this.catalogId > 0)
        return `${URL}/api/hgc/file/view/${this.catalogId}`
      else if (this.photo) return `${URL}/api/hgc/file/view/${this.photo}`
      else return require('assets/image/etc/etc-hotel-noimg.svg')
    },
    themeImgPath() {
      //console.info('this.bannerImgPath', this.bannerImgPath)
      if (this.bannerImgPath > 0)
        return `${URL}/api/hgc/file/view/${this.bannerImgPath}`
      else return require('assets/image/etc/etc-hotel-noimg.svg')
    },
    positionOptionAdditionalNames() {
      const array = new Array(
        this.positionOptionNames,
        this.positionAdditionalNames,
      )
      let result = array
        .filter(item => item !== null && item !== undefined && item !== '')
        .join(', ')
      if (result) {
        result = '(' + result + ')'
      }
      return result
    },
    isNegoAvailable() {
      if (this.isNegoUpdated) return false
      if (!this.pushStatus) return false

      //근무응답, 합격확인의 경우에만 버튼으로 활성화
      if (this.pushStatus === 'SCALL' || this.pushStatus === 'SOK') return true
      return false
    },

    pushStatusButtonText() {
      if (!this.pushStatus) return ''

      //근무요청 상태와 근무확정 상태에서는 그 다음 상태를 버튼으로 활성화 한다.
      if (this.pushStatus === 'SCALL' || this.pushStatus === 'SOK') {
        const targetIndex = PUSH_STATUS.findIndex(
          status => status.value === this.pushStatus,
        )
        //그 다음 상태를 표시
        const requestPushStatus = PUSH_STATUS[targetIndex + 1].name
        return requestPushStatus
      }
      //근무응답, 합격확인 상태는 그 상태 그대로 표시하면 된다. 버튼 역할하지 않는다
      else {
        return PUSH_STATUS_NAME[this.pushStatus]
      }
    },

    applyMessage() {
      // if (!this.applyText) return '지원불가' //기본값
      //console.info('this.pushStatus', this.pushStatus)
      if (this.pushStatus == '') {
        return '근무 매칭이 되지 않았습니다.'
      } else {
        if (this.recruitingStatus == 'CLOSE') {
          //마감 된 경우
          //근무요청 상태와 근무확정 상태에서는 그 다음 상태를 버튼으로 활성화 한다.
          if (this.pushStatus === 'SCALL') {
            //근무요청o
            return '근무 요청을 하였으나 지원하지 않았습니다.'
          }
          if (this.pushStatus === 'ROK') {
            //근무응답o
            return '지원을 하였으나 합격이 되지 않았습니다.'
          }
          if (this.pushStatus === 'SOK') {
            //근무확정
            return '근무확정이 되었으나 합격확인을 하지 않았습니다.'
          }
          if (this.pushStatus === 'RSOK') {
            //합격확인o
            return '최종 합격 하였습니다.'
          }
          if (this.pushStatus === 'CANCEL') {
            //취소
            return '근무 확정 또는 합격을 취소 하였습니다.'
          } else {
            return PUSH_STATUS_NAME[this.pushStatus]
          }
        } else {
          //근무요청 상태와 근무확정 상태에서는 그 다음 상태를 버튼으로 활성화 한다.
          if (this.pushStatus === 'SCALL') {
            return '지원이 가능 합니다.'
          }
          if (this.pushStatus === 'ROK') {
            return '업체의 합격을 기다리고 있습니다.'
          }
          if (this.pushStatus === 'SOK') {
            return '근무확정이 되었습니다. 합격 확인을 클릭해주세요.'
          }
          if (this.pushStatus === 'RSOK') {
            return '최종 확정 되었습니다. 근무일자에 출근 바랍니다.'
          }
          if (this.pushStatus === 'CANCEL') {
            return '근무 확정 또는 합격을 취소 하였습니다.'
          } else {
            //합격확인 = 합격확인
            return PUSH_STATUS_NAME[this.pushStatus]
          }
        }
      }
    },
  },
  methods: {
    // onFilter(query) {
    //   //console.info('onSearch query : ', query)
    //   this.schCompanyId = query.schCompanyId
    //   // this.schRegion = query.schRegion
    //   this.fetchGeneralList()
    // },
    matchViewTerms() {
      if (this.$route.params.backRoute === 'JobDocument') {
        this.matchView = true
        this.$cookies.set('JobDocument')
      }
      if (this.$cookies.isKey('JobDocument')) {
        this.matchView = true
      }
    },
    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },
    addressCopy(text) {
      var t = document.createElement('textarea')
      document.body.appendChild(t)
      t.value = `${text}` // 원하는 텍스트
      t.select()
      document.execCommand('copy')
      this.$toasted.show('클립보드에 주소가 복사되었습니다.')
    },
    goBackPage() {
      if (window.history.length > 2) {
        this.$router.go(-1)
      } else {
        this.moveToPage('/request')
      }
    },
    async fetchJobDetail() {
      const res = await getRecruitDetail({
        documentNo: this.$route.params.id,
      })
      //console.info('res', res)
      this.setStateValues(res)
      this.schCompanyId = res.companyId
      this.fetchGeneralList()
    },
    async callNego() {
      if (!this.isNegoAvailable) return
      if (!this.pushStatus) return
      if (!this.jobRequestId) return
      if (!this.recruitingStatus || this.recruitingStatus != 'RECRUITING')
        return

      const targetStatus = PUSH_STATUS.find(
        status => status.name === this.pushStatusButtonText,
      )
      const pushStatus = targetStatus.value

      try {
        await postApplyJob({
          jobRequestId: this.jobRequestId,
          senderId: this.account.userId,
          pushStatus,
        })
        this.isNegoUpdated = true //버튼비활성화
        this.$toasted.show(`${this.pushStatusButtonText} 하셨습니다.`)
      } catch (e) {
        console.error(e)
      }
    },
    goLoadMap(hotelName, latLon) {
      //console.info(hotelName, latLon)
      //	var url = 'http://map.daum.net/link/to/'+clubName+','+latlng;
      var url = 'https://map.daum.net/link/map/' + hotelName + ',' + latLon
      if (latLon == '') {
        url = 'https://map.daum.net/link/search/' + hotelName
      }
      window.open(url)
    },

    // async applyRequest() {
    //   const res = await postApplyJob({
    //     jobRequestId: this.jobRequestId,
    //     pushStatus: this.pushStatus,
    //   })
    // },
  },
  async created() {
    await this.fetchJobDetail()
    await this.matchViewTerms()
    console.log('matchView : ', this.matchView)
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
}
</script>

<style lang="scss" scoped>
main.sub.__find {
  .btn_wrap > button {
    margin-right: 8px;
  }
  .btn_wrap {
    margin-block: 30px;
  }
  .addressBox {
    div {
      a {
        &.btn-findingAWay {
          background: url('~assets/image/main/FindingAWay.png') no-repeat center;
          background-size: 50px auto;
        }
        &.btn-copyAddress {
          background: url(~assets/image/ico/ico-link.svg) no-repeat center;
          background-size: 40px auto;
        }
      }
    }
  }
}
#companyStatusBr {
  display: none;
}
@media (max-width: 1024px) {
  #companyStatusBr {
    display: block !important;
  }
}
</style>
