<template>
  <div id="pop-cancel" class="dialog" :class="{ on, ing }">
    <div class="pop-wrap">
      <h1>취소하기</h1>
      <div class="cont">
        <textarea
          v-model="cancelReason"
          class="textarea"
          placeholder="취소사유"
        ></textarea>
      </div>
      <div class="pop-btn-wrap">
        <button type="button" class="btn-cancel" @click="cancelRequest">
          취소하기
        </button>
        <button type="button" class="btn-close" @click="close">닫기</button>
      </div>
    </div>
  </div>
</template>

<script>
import popupMixin from 'Mixins/popup'

export default {
  mixins: [popupMixin],
  data() {
    return {
      cancelReason: '',
    }
  },
  methods: {
    cancelRequest() {
      this.$emit('cancelRequest', this.cancelReason)
      this.close()
    },
  },
}
</script>

<style lang="scss" scoped>
#pop-cancel {
  position: fixed;
}
</style>
