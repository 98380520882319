<template>
  <div class="sub-cont">
    <div class="myp-buy-wrap">
      <div class="opt-item-wrap-con">
        <div class="opt-item-wrap">
          <select v-model="pushStatus" class="select">
            <option hidden>전체</option>
            <option
              v-for="pushSt in PUSH_STATUS"
              :key="pushSt.value"
              :value="pushSt.value"
            >
              {{ pushSt.name }}
            </option>
          </select>
          <input
            v-model="schKeyword"
            type="text"
            class="inp"
            placeholder="이름 검색"
            @keydown="searchKey"
          />
        </div>
        <button type="button" @click="onGoPage(1)">검색</button>
      </div>
      <ul class="ulTbl01">
        <li class="category">
          <ul>
            <li>이름</li>
            <li>성별</li>
            <li>나이</li>
            <li>경력</li>
            <li>상태</li>
            <li v-if="!windowWid" style="width: 25px"></li>
          </ul>
        </li>
        <li
          v-for="(docItem, idx) in documentByList"
          :key="`docItem_${idx}`"
          @click="DeadlineView(`docItem_${idx}`)"
          style="cursor: pointer"
          :class="{
            on: doctNum === `docItem_${idx}`,
          }"
        >
          <ul>
            <li>{{ docItem.seeker }}</li>
            <li>
              {{
                `${
                  docItem.gender === '0'
                    ? '남자'
                    : docItem.gender === '1'
                    ? '여자'
                    : '미설정'
                }`
              }}
            </li>
            <li>{{ docItem.age + '세' }}</li>
            <li>
              {{
                `${
                  docItem.career === '0'
                    ? '신입'
                    : docItem.career === '1'
                    ? '경력직'
                    : ''
                }`
              }}
            </li>
            <li class="status-wrap">
              <div
                class="status"
                :class="{
                  wait: docItem.pushStatus === PUSH_STATUS[1].value,
                  hold: docItem.pushStatus === PUSH_STATUS[5].value,
                  succ: docItem.pushStatus === PUSH_STATUS[4].value,
                  answ: docItem.pushStatus === PUSH_STATUS[2].value,
                  deci: docItem.pushStatus === PUSH_STATUS[3].value,
                }"
              >
                {{ PUSH_STATUS_NAME[docItem.pushStatus] }}
              </div>
            </li>
            <li v-if="!windowWid" style="width: 25px"></li>
          </ul>
          <div class="MemInfo" :class="{ on: doctNum === `docItem_${idx}` }">
            <div class="profile-info-wrap">
              <div class="pic" :style="imgPath(docItem.photo)"></div>
              <div class="info-header">
                <strong> {{ docItem.seeker }}</strong
                ><span>
                  {{
                    `${
                      docItem.gender === '0'
                        ? '남자'
                        : docItem.gender === '1'
                        ? '여자'
                        : '미설정'
                    }`
                  }}
                  / {{ docItem.age + '세' }} /
                  {{
                    `${
                      docItem.career === '0'
                        ? '신입'
                        : docItem.career === '1'
                        ? '경력직'
                        : ''
                    }`
                  }}
                </span>
              </div>
              <div class="e01">
                {{
                  `${docItem.workingPlace} :  ${docItem.jobPositionName} - ${docItem.jobPositionDtlName}`
                }}
              </div>
            </div>
            <dl>
              <dt>근무 매칭 현황</dt>
              <dd>
                <ul class="info-item-list">
                  <li v-if="docItem.workRequestDt">
                    <div class="value">
                      <div class="status req">근무요청</div>
                      <div class="e01">{{ docItem.workRequestDt }}</div>
                    </div>
                  </li>
                  <li v-if="docItem.workReplyDt">
                    <div class="value">
                      <div class="status resp">근무응답</div>
                      <div class="e01">{{ docItem.workReplyDt }}</div>
                    </div>
                  </li>
                  <li
                    v-if="
                      docItem.pushStatus === PUSH_STATUS[3].value ||
                      docItem.pushStatus === PUSH_STATUS[4].value ||
                      docItem.pushStatus === PUSH_STATUS[5].value
                    "
                  >
                    <div class="value">
                      <div class="status ok">근무확정</div>
                      <div v-if="docItem.recruitOkDt" class="e01">
                        {{ docItem.recruitOkDt }}
                      </div>
                      <!--div v-else class="e01">대기중</div-->
                    </div>
                  </li>
                  <li v-if="docItem.passDt">
                    <div class="value">
                      <div class="status succ">합격확인</div>
                      <div class="e01">{{ docItem.passDt }}</div>
                    </div>
                  </li>
                  <li v-if="docItem.pushStatus === PUSH_STATUS[5].value">
                    <div class="value">
                      <div class="status succ">취소</div>
                      <div class="e01">{{ docItem.cancelDt }}</div>
                      <div class="e01">{{ docItem.cancelReason }}</div>
                    </div>
                  </li>
                </ul>
              </dd>
              <dt>근무 정보</dt>
              <dd>
                <ul class="info-item-list">
                  <li>
                    <div class="key">근무기간</div>
                    <div class="value">
                      <div class="e01">
                        {{
                          `${docItem.workStartDate} ~ ${docItem.workEndDate}`
                        }}
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="key">근무시간</div>
                    <div class="value">
                      <div class="e01">
                        {{
                          `${docItem.workStartTime} ~ ${docItem.workEndTime}`
                        }}
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="key">마감일시</div>
                    <div class="value">
                      <div class="e01" style="color: rgb(97, 158, 246)">
                        {{ docItem.closingDt }}
                      </div>
                    </div>
                  </li>
                </ul>
              </dd>
            </dl>
            <div class="btn-wrap">
              <button
                type="button"
                class="btn-ok"
                v-if="
                  docItem.pushStatus === PUSH_STATUS[2].value &&
                  docItem.negoYn === 'Y' //&&
                  //requestDetail.recruitingStatus === 'RECRUITING'
                "
                @click="clickOk(docItem.jobPushId)"
              >
                구인 확정
              </button>
              <button
                v-if="docItem.pushStatus !== PUSH_STATUS[5].value"
                type="button"
                class="btn-cancel"
                @click="onCancel(docItem.jobPushId)"
              >
                취소
              </button>
            </div>
          </div>
          <cancel-popup
            :visible.sync="cancelPopupVisible"
            @cancelRequest="onCancelRequest"
          ></cancel-popup>
        </li>
        <li v-if="documentByList.length == 0">
          <ul>
            <li class="length0">근무요청 내역이 존재하지 않습니다.</li>
          </ul>
        </li>
      </ul>
      <!-- 페이징 추가 -->
      <Pagination
        :totalElements="totalElements"
        :currentPageIndex="currentPageIndex"
        :listRowCount="listRowCount"
        :pageLinkCount="pageLinkCount"
        @goPage="onGoPage"
      ></Pagination>
    </div>
  </div>
</template>

<script>
import { getMatchPeopleList, putRequestStatus } from 'Api/modules'
import { PUSH_STATUS, PUSH_STATUS_NAME } from 'Configs/pushStatus'
import Pagination from 'Common/Pagination'
import CancelPopup from '../popup/CancelPopup'
import { URL } from 'Api/api'
import { mapGetters } from 'vuex'
export default {
  components: {
    Pagination,
    CancelPopup,
  },
  mixins: [],
  data() {
    return {
      PUSH_STATUS: Object.freeze(PUSH_STATUS),
      PUSH_STATUS_NAME: Object.freeze(PUSH_STATUS_NAME),

      pushStatus: PUSH_STATUS[0].value,
      schKeyword: '',

      documentByList: [], //근무요청 목록
      // paging
      pageNumber: 0,
      totalElements: 0,
      listRowCount: 10,
      pageLinkCount: 5,
      currentPageIndex: 1,
      isVisibleLeftTab: true,
      doctNum: null,
      cancelPopupVisible: false,
      pushId: '',
    }
  },
  computed: {
    ...mapGetters(['accountUserImg']),
  },
  watch: {
    '$route.query': {
      deep: true,
      handler() {
        this.setFromQueryParam()
        this.search(false)
      },
    },
  },
  methods: {
    onCancel(targetId) {
      this.cancelPopupVisible = true
      this.pushId = targetId
    },
    async onCancelRequest(cancelReason) {
      try {
        await putRequestStatus({
          pushId: this.pushId,
          userId: this.account.userId,
          senderId: this.account.userId,
          pushStatus: PUSH_STATUS[5].value, //취소
          cancelReason,
        })
        this.$toasted.show('근무요청이 취소되었습니다.')
        setTimeout(() => {
          this.$router.go() // 새로고침
        }, 300)
      } catch (error) {
        console.error(error)
      }
    },
    async clickOk(targetId) {
      // const res = await putRequestStatus({
      try {
        await putRequestStatus({
          pushId: targetId,
          userId: this.account.userId,
          senderId: this.account.userId,
          pushStatus: PUSH_STATUS[3].value, //근무확정
          cancelReason: '',
        })
        this.$toasted.show('근무확정 되었습니다.')
        setTimeout(() => {
          this.$router.go() // 새로고침
        }, 300)
      } catch (error) {
        console.error(error)
      }
    },
    searchKey(e) {
      if (e.keyCode == 13) {
        this.search()
      }
    },
    imgPath(photo) {
      // 회사 이미지 필요
      if (photo) return `background : url(${URL}/api/hgc/file/view/${photo})`
      else return require('assets/image/etc/etc-hotel-noimg.svg')
    },
    DeadlineView(doctNo) {
      // this.doctNum = doctNo
      if (this.doctNum === doctNo) this.doctNum = null
      else {
        this.doctNum = doctNo
      }
    },
    onGoPage(curPage) {
      this.currentPageIndex = curPage
      this.pageNumber = curPage - 1 //pageNumber 는 현재페이지에서 -1
      this.search()
    },
    async search() {
      const res = await getMatchPeopleList({
        pushStatus: this.pushStatus,
        documentNo: this.$route.params.id,
        schKeyword: this.schKeyword,
        employerId: this.account.userId,
        pageNumber: this.pageNumber,
      })
      this.documentByList = res.content
      this.totalElements = res.totalElements
      this.currentPageIndex = res.pageable.pageNumber + 1
      this.pageNumber = res.pageable.pageNumber
      // if (setQuery) {
      //   const query = {
      //     pushStatus: this.pushStatus,
      //     schKeyword: this.schKeyword,
      //     pageNumber: this.pageNumber,
      //     seeker: this.seeker,
      //   }

      //   this.$router.push({
      //     name: 'jobDetail',
      //     query,
      //   })
      // }
    },

    setFromQueryParam() {
      const { pushStatus, schKeyword, pageNumber } = this.$route.query

      this.pushStatus = pushStatus
      this.schKeyword = schKeyword
      this.pageNumber = pageNumber
    },
    workDate(str) {
      return String(str).substr(5)
    },
  },
  async created() {
    this.setFromQueryParam()
    await this.search()
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    console.log('documentByConditions', this.documentByConditions)
    console.log('requestList', this.requestList)
  },
}
</script>

<style lang="scss" scoped>
.tbl01 {
  tr > td:nth-child(2) {
    cursor: pointer;
  }
}
.pic {
  background: url(~assets/image/etc/etc-profile01.jpg) no-repeat center center;
}
</style>
