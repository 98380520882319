import { RECRUITING_STATUS } from 'Configs/registJob'

export default {
  data() {
    return {
      RECRUITING_STATUS: Object.freeze(RECRUITING_STATUS),

      jobPostTitle: '',
      companyName: '',
      businessTypeName: '',
      jobManager: '',
      managerPhone: '',
      workingAddress: '',
      workingAddressDetail: '',
      jobPositionName: '',
      jobPositionDtlName: '',
      positionCnt: '',
      positionOptionNames: '',
      positionAdditionalNames: '',
      workPeriodType: '',
      workStartDate: '',
      workEndDate: '',
      catalogId: '',
      workStartTime: '',
      workEndTime: '',
      gender: '',
      age: '',
      ageMin: '',
      ageMax: '',
      career: '',
      nationality: '',
      foreignLanguage: '',
      supportOptions: '',
      majorInsurances: '',
      incentives: '',
      welfares: '',
      spcConds: '',
      note: '',
      openingType: '',
      openingReservationDt: '',
      closingDt: '',
      closingTime: '',
      regDt: '',
      photo: '',
      pushStatus: '',
      jobRequestId: '',
      recruitingStatus: '',
      bannerImgPath: '',

      //   boxProduct: '',
      //   listupProduct: '',
      //   pushProduct: '',
      //   emphasisProducts: '', //201,202
      //   emphasisCodes: '',

      passDecisionType: '',
      passDecisionTime: '',
      companyId: '',

      payAmt: '',
      answerCnt: '',
      passCnt: '',
      scallCnt: '',
      sokCnt: '',
      latLon: '',
      hotelName: '',
      //   payVat: '',
      //   productUseAmt: '',
      //   vat: '',
      //   totalPayAmt: '',
    }
  },
  methods: {
    setStateValues(object) {
      this.jobPostTitle = object.jobPostTitle
      this.companyName = object.companyName
      this.businessTypeName = object.businessTypeName
      this.jobManager = object.jobManager
      this.managerPhone = object.managerPhone
      this.workingAddress = object.workingAddress
      this.workingAddressDetail = object.workingAddressDetail
      this.jobPositionName = object.jobPositionName
      this.jobPositionDtlName = object.jobPositionDtlName
      this.positionCnt = object.positionCnt
      this.positionOptionNames = object.positionOptionNames
      this.positionAdditionalNames = object.positionAdditionalNames
      this.workPeriodType = object.workPeriodType
      this.workStartDate = object.workStartDate
      this.workEndDate = object.workEndDate
      this.workStartTime = object.workStartTime
      this.workEndTime = object.workEndTime
      this.gender = object.gender
      this.age = object.age
      this.ageMin = object.ageMin
      this.ageMax = object.ageMax
      this.career = object.career
      this.nationality = object.nationality
      this.foreignLanguage = object.foreignLanguage
      this.supportOptions = object.supportOptions
      this.majorInsurances = object.majorInsurances
      this.incentives = object.incentives
      this.welfares = object.welfares
      this.spcConds = object.spcConds
      this.note = object.note
      this.openingType = object.openingType
      this.openingReservationDt = object.openingReservationDt
      this.closingDt = object.closingDt
      this.closingTime = object.closingTime
      this.regDt = object.regDt
      this.photo = object.photo
      this.pushStatus = object.pushStatus
      this.jobRequestId = object.jobRequestId
      this.recruitingStatus = object.recruitingStatus
      this.catalogId = object.catalogId
      this.bannerImgPath = object.bannerImgPath
      this.hotelName = object.hotelName
      this.latLon = object.latLon

      //   boxProduct: '',
      //   listupProduct: '',
      //   pushProduct: '',
      //   emphasisProducts: '', //201,202
      //   emphasisCodes: '',

      this.passDecisionType = object.passDecisionType
      this.passDecisionTime = object.passDecisionTime
      this.companyId = object.companyId
      this.payAmt = object.payAmt
      this.answerCnt = object.answerCnt
      this.passCnt = object.passCnt
      this.scallCnt = object.scallCnt
      this.sokCnt = object.sokCnt

      //   payAmt: '',
      //   payVat: '',
      //   productUseAmt: '',
      //   vat: '',
      //   totalPayAmt: '',
    },
    // getPushStatusName(pushStatus) {
    //   if (pushStatus === 'CANCEL') return '취소'
    //   else if (pushStatus === 'ROK') return '근무응답'
    //   else if (pushStatus === 'RSOK') return '합격'
    //   else if (pushStatus === 'SCALL') return '근무요청'
    //   else if (pushStatus === 'SOK') return '근무확정'
    // },
    // // 상태에 따라, 버튼 활성화 혹은 비활성화 처리
    // availableApply(pushStatus) {
    //   if (pushStatus === 'SCALL' || pushStatus === 'SOK') return true
    //   else return false
    // },
  },
}
